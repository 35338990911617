:root, .light, .light-theme {
  --gray-1: #fcfcfc;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e0e0e0;
  --gray-6: #d9d9d9;
  --gray-7: #cecece;
  --gray-8: #bbbbbb;
  --gray-9: #8d8d8d;
  --gray-10: #838383;
  --gray-11: #646464;
  --gray-12: #202020;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root, .light, .light-theme {
      --gray-1: color(display-p3 0.988 0.988 0.988);
      --gray-2: color(display-p3 0.975 0.975 0.975);
      --gray-3: color(display-p3 0.939 0.939 0.939);
      --gray-4: color(display-p3 0.908 0.908 0.908);
      --gray-5: color(display-p3 0.88 0.88 0.88);
      --gray-6: color(display-p3 0.849 0.849 0.849);
      --gray-7: color(display-p3 0.807 0.807 0.807);
      --gray-8: color(display-p3 0.732 0.732 0.732);
      --gray-9: color(display-p3 0.553 0.553 0.553);
      --gray-10: color(display-p3 0.512 0.512 0.512);
      --gray-11: color(display-p3 0.392 0.392 0.392);
      --gray-12: color(display-p3 0.125 0.125 0.125);
    }
  }
}