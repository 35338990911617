$inter-font-display: swap !default;
$inter-font-name: 'Inter' !default;
$inter-font-suffix: '' !default;
$inter-font-path: 'web' !default;
$inter-font-path-suffix: '?v=4.0' !default;

@mixin weight-100-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Thin#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-100-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 100;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-ThinItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-100 {
  @include weight-100-normal;
  @include weight-100-italic;
}

@mixin weight-200-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-ExtraLight#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-200-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 200;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-ExtraLightItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-200 {
  @include weight-200-normal;
  @include weight-200-italic;
}

@mixin weight-300-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Light#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-300-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 300;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-LightItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-300 {
  @include weight-300-normal;
  @include weight-300-italic;
}

@mixin weight-400-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Regular#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-400-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Italic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-400 {
  @include weight-400-normal;
  @include weight-400-italic;
}

@mixin weight-500-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Medium#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-500-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-MediumItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-500 {
  @include weight-500-normal;
  @include weight-500-italic;
}

@mixin weight-600-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-SemiBold#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-600-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 600;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-SemiBoldItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-600 {
  @include weight-600-normal;
  @include weight-600-italic;
}

@mixin weight-700-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Bold#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-700-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 700;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-BoldItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-700 {
  @include weight-700-normal;
  @include weight-700-italic;
}

@mixin weight-800-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-ExtraBold#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-800-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 800;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-ExtraBoldItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-800 {
  @include weight-800-normal;
  @include weight-800-italic;
}

@mixin weight-900-normal {
  @font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-Black#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-900-italic {
  @font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 900;
    font-display: $inter-font-display;
    src: url("#{$inter-font-path}/#{$inter-font-name}-BlackItalic#{$inter-font-suffix}.woff2#{$inter-font-path-suffix}") format("woff2");
  }
}

@mixin weight-900 {
  @include weight-900-normal;
  @include weight-900-italic;
}

@mixin all {
  @include weight-100;
  @include weight-200;
  @include weight-300;
  @include weight-400;
  @include weight-500;
  @include weight-600;
  @include weight-700;
  @include weight-800;
  @include weight-900;
}

@mixin normal {
  @include weight-100-normal;
  @include weight-200-normal;
  @include weight-300-normal;
  @include weight-400-normal;
  @include weight-500-normal;
  @include weight-600-normal;
  @include weight-700-normal;
  @include weight-800-normal;
  @include weight-900-normal;
}

@mixin italic {
  @include weight-100-italic;
  @include weight-200-italic;
  @include weight-300-italic;
  @include weight-400-italic;
  @include weight-500-italic;
  @include weight-600-italic;
  @include weight-700-italic;
  @include weight-800-italic;
  @include weight-900-italic;
}
