.c-icon-button {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 5px;
  background-color: transparent;
  color: var(--ion-color-dark);
  cursor: pointer;
  font-size: 16px;

  &__icon {
  }

  &:hover:not(:disabled) {
    background-color: var(--ion-color-light);
    color: var(--ion-color-dark);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.c-button-link {
  color: var(--ion-color-dark);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

ion-button {
  --border-radius: 6px;
}

.c-button {

  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 6px;
  border-radius: 25px;
  background: linear-gradient(
    86deg,
    var(--ion-color-primary-shade) 11.14%,
    var(--ion-color-primary) 113.29%
  );
  color: white;

  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  gap: 8px;

  &:hover {
    opacity: 0.8;
  }

  &--clear {
    border: unset;
    background: transparent;
    color: var(--ion-color-primary);
  }
}
