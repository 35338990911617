.u-glossy {
  border: 3px solid rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  backdrop-filter: blur(47.86407470703125px);
  background: linear-gradient(
    86deg,
    rgba(255, 255, 255, 0.49) 11.14%,
    rgba(255, 255, 255, 0.05) 113.29%
  );
  background-blend-mode: overlay;
  box-shadow: 0px 1.197px 29.915px 0px rgba(69, 42, 124, 0.03);

  ion-item {
    --background: transparent;
  }
}
