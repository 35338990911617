.sidebar-entry {
  display: flex;
  width: 100%;
  height: 28px;

  color: var(--ion-color-medium);
  gap: 16px;

  &__label {
    display: flex;
    width: 108px;
    align-items: center;
  }

  &__value {
    display: flex;
    align-items: center;
    gap: 8px;

    ion-icon {
      color: var(--ion-color-tertiary-8);
    }
  }
}

.c-overview-items {
  display: flex;

  margin-bottom: 32px;
  font-size: 14px;
  gap: 0 4px;
  text-align: left;

  &__item {
    display: flex;
    flex: 1;
    padding: 4px;

    color: var(--ion-color-medium);

    &:first-child {
      padding-left: 0px;
    }
  }

  &__indicator {
    padding: 0px 4px;
  }

  &__label {
    height: 26px;
  }

  &__value {
    height: 16px;
    color: var(--ion-color-dark);
  }
}
