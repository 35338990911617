.c-celebration-dialog {
  --viewport-padding: 25px;
  position: absolute;
  z-index: 2147483647;
  top: 48px;
  left: 0;
  display: flex;

  max-width: 100vw;
  flex-direction: column;
  padding: var(--viewport-padding);
  margin: 0;
  gap: 10px;
  list-style: none;
  outline: none;

  transform: translateX(calc(-100% - var(--viewport-padding)));

  transition: transform cubic-bezier(0.16, 1, 0.3, 1) 600ms;

  &--visible {
    transform: translateX(0);
  }

  &__wrapper {
    display: grid;
    align-items: center;
    padding: 15px;
    border-radius: 6px;
    background-color: #0d2d2a;
    box-shadow:
      0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    column-gap: 10px;
    grid-template-areas: "title icon" "description icon";
    grid-template-columns: auto max-content;
  }

  &__title {

    display: flex;
    align-items: center;
    margin-bottom: 5px;
    color: #adf0dd;
    font-size: 15px;
    font-weight: 500;
    gap: 8px;
    grid-area: title;
  }

  &__description {
    margin: 0;
    color: #0bd8b6;
    font-size: 13px;
    grid-area: description;
    line-height: 1.3;
  }

  &__icon {
    color: #adf0dd;
    font-size: 22px;
    grid-area: icon;
  }
}
