/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@use "inter-ui/default" with (
  $inter-font-display: swap,
  $inter-font-path: "inter-ui/web"
);

@use "inter-ui/variable" with (
  $inter-font-display: swap,
  $inter-font-path: "inter-ui/variable"
);

@include default.all;
@include variable.all;

html {
  font-family: "Inter", "system-ui";

  ::selection {
    background-color: #ecd9fa;
  }

  ::-moz-selection {
    background-color: #ecd9fa;
  }
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "InterVariable", "system-ui";
  }
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Colors */
@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/red.css";
@import "@radix-ui/colors/violet.css";
@import "@radix-ui/colors/gray.css";

@import "./theme/glossy.scss";
@import "./theme/keyboard.scss";
@import "./theme/sidebar.scss";
@import "./theme/buttons.scss";
@import "./theme/dialogs.scss";
@import "./theme/select.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: CommitMonoRegular;
  src: url("assets/fonts/CommitMono-Regular.woff2") format("woff2");
}

.monospace {
  font-family: "CommitMonoRegular", monospace;
  font-feature-settings: "tnum";
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

.u-hide-in-view {
  opacity: 0;
  pointer-events: none;
}

.u-flex {
  display: flex;
}

.ion-page {
  background-color: white;
}

/* .koe-theme {
  .ion-page {
    background-color: #111111;

    --color: white;
    color: white !important;
  }
}
 */
.bg-gradient {
  position: fixed;
  z-index: 1;
  top: 20%;
  left: 30%;

  width: var(--size);
  min-width: 500px;
  height: var(--size);
  min-height: 500px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: movearound var(--speed) var(--easing) infinite;
  background-color: #222;
  background-image: linear-gradient(#d19dff, #0bd8b6);
  filter: blur(calc(var(--size) / 8));

  opacity: 0.3;
  pointer-events: none;
  transform: translate(-50%, -50%);

  transition:
    background-image 1s ease,
    opacity 1s ease;

  --size: 50vw;
  --speed: 30s;
  --easing: linear;
  --easing: cubic-bezier(0.65, 0, 0.35, 1);

  &--top {
    left: 0;
  }

  &--secondary {
    background-image: linear-gradient(#00a2c7 30%, #4ccce6);
    --size: 80vw;
    opacity: 0.3;
  }

  &--primary {
    background-image: linear-gradient(#8e4ec6 30%, #d19dff);
    --size: 80vw;
    opacity: 0.25;
  }

  &--tertiary {
    top: 0;
    left: 0;
    animation: pulse 7s var(--easing) forwards;
    background-image: linear-gradient(#12a594 50%, #0bd8b6);
    opacity: 0.7;
    transform: rotate(0deg) scale(0.2);
    --size: 100vw;
  }

  &--hidden {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    transform: rotate(0deg) scale(0.2);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1.5);
  }
}

@keyframes movearound {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  50% {
    transform: rotate(180deg) translate(-10vw, 20vh);
  }
  100% {
    transform: rotate(360deg) translate(0, 0);
  }
}

.bg-gradient-relative {
  position: fixed;
  z-index: 1;

  top: 60%;

  width: var(--size);
  min-width: 400px;
  height: var(--size);
  min-height: 400px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: movearound var(--speed) var(--easing) infinite;
  background-color: #222;
  background-image: linear-gradient(#0bd8b6, #9a5cd0);
  filter: blur(calc(var(--size) / 8));
  opacity: 0.2;
  pointer-events: none;

  --size: 40vw;
  --speed: 30s;
  --easing: linear;
  --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
}

@keyframes movearound {
  0% {
    transform: rotate(0deg) translate(0, 0);
  }
  50% {
    transform: rotate(180deg) translate(-80px, 50px);
  }
  100% {
    transform: rotate(360deg) translate(0, 0);
  }
}

.f-title {
  color: var(--ion-color-dark);
  font-size: 22px;
  font-weight: 500;
  line-height: 2rem;
}

.blurred {
  filter: blur(4px);
}

.c-text-primary {
  color: var(--ion-color-primary);
}

.c-text-secondary {
  color: var(--ion-color-secondary);
}

.u-cursor-pointer {
  cursor: pointer;
}

h2 {
  @apply text-2xl mb-3;
}

h5 {
  @apply mb-3;
}

a {
  @apply underline text-focus;
}

li {
}
