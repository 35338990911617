@import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button {
  all: unset;
}

.ng-dropdown-panel {
  overflow: hidden;
  border-radius: 6px;
  background-color: white;

  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.ng-dropdown-panel-items {
  padding: 5px;
}

.ng-option {
  position: relative;
  display: flex;
  min-width: 48px;
  height: 25px;
  align-items: center;
  padding: 0 35px 0 25px;
  border-radius: 3px;
  color: var(--violet-11);
  font-size: 13px !important;
  line-height: 1;
  user-select: none;
}

.ng-value-label {
  font-size: 13px !important;
}

.ng-select {
  padding: 0px 4px;
}

.ng-select-container {
  &:hover {
    background-color: var(--mauve-3);
  }
}

.ng-option[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.ng-option-selected,
.ng-option-marked {
  background-color: var(--violet-3) !important;
  color: var(--violet-1);
  outline: none;
}

/* Additional styles for ng-select */
.ng-option:hover {
  background-color: var(--mauve-3);
}

.ng-option:focus {
  box-shadow: 0 0 0 2px black;
}

.ng-option[data-placeholder] {
  color: var(--violet-9) !important;
}

.ng-option .SelectIcon {
  color: var(--violet-11) !important;
}

.ng-option .SelectItemIndicator {
  position: absolute;
  left: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ng-option .SelectScrollButton {
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}
.scroll-host {
  overflow-y: auto;
  scrollbar-color: var(--mauve-7) var(--mauve-1);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: var(--mauve-1);
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid var(--mauve-1);
    border-radius: 10px;
    background-color: var(--mauve-3);
  }
}

.ng-option-selected {
  background-color: var(--mauve-11);
}

.ng-select.ng-select--glassy {
  display: inline-block !important;

  padding: 2px 8px 2px 0px;
  border: 1px solid var(--ion-color-primary);

  border-radius: 6px;
  background-color: transparent;
  cursor: pointer !important;

  font-family: "CommitMonoRegular", monospace;

  .ng-arrow-wrapper {
    display: none;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  .ng-value {
    height: 100%;
    color: var(--ion-color-primary-shade);
  }

  .ng-select-container {
    border: none !important;
    background-color: transparent;
    cursor: pointer !important;
    outline: none;

    &:hover {
      border: none !important;
    }
  }

  .ng-value-label {
    font-size: 28px !important;
    font-weight: 500;
  }

  .ng-input {
    top: 50%;
    transform: translateY(-50%);
  }

  @media (max-width: 600px) {
    font-size: 20px;

    .ng-value-label {
      font-size: 20px !important;
    }
  }
}

.ng-dropdown-panel {
  z-index: 109912981;
}
