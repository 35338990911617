.c-keyboard-shortcut {
  padding: 8px 4px;
  border: 1px solid rgba(255, 255, 255, 0.5);

  border-radius: 4px;
  margin-left: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  color: black;

  font-size: 14px;
}
